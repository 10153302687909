<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        <div class="mr-auto p-2 bd-highlight">
          Bônus
        </div>
        <v-btn
          color="info"
          class="p-2 bd-highlight"
          @click="openModalAddCommission()"
        >
          Adicionar
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        :loading="loading"
        :items-per-page="pageOptions.itemsPerPage"
        disable-sort
        hide-default-footer
        :page.sync="pageOptions.page"
        loading-text="Carregando dados..."
        @page-count="pageCount = $event"
      >
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.id === loadingSpinner ? false : true"
            medium
            class="me-2"
            @click="getItemBonusById(item.id)"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === loadingSpinner ? true : false"
            indeterminate
            :size="25"
            color="gray"
          >
          </v-progress-circular>
        </template>
      </v-data-table>
      <v-card-text>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageOptions.page"
              total-visible="5"
              :length="pageOptions.pageCount"
              @input="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="openModal"
      width="500"
    >
      <modal-bonus
        :bonus-data="dataToModalById"
        :mode="mode"
        :close-modal="closeModal"
        :updated-table="updatedTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiPencilOutline } from '@mdi/js'
import modalBonus from './modalBonus.vue'

export default {
  components: {
    modalBonus,
  },
  data() {
    return {
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      mode: '',
      headers: [
        { text: 'BONIFICAÇÃO', value: 'description', align: 'center' },
        { text: '2° PARCELA', value: 'parcel_2', align: 'center' },
        { text: '3° PARCELA', value: 'parcel_3', align: 'center' },
        { text: '4° PARCELA', value: 'parcel_4', align: 'center' },
        { text: 'ENTREGA', value: 'delivery', align: 'center' },
        { text: 'ENT. CARTA', value: 'base_calc', align: 'center' },
        { text: 'AÇÕES', value: 'actions', align: 'center' },
      ],
      dataTable: [],
      loading: true,
      dataToModalById: [],
      icons: {
        mdiPencilOutline,
      },
      openModal: false,
      loadingSpinner: '',
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      await axiosIns.get('api/v1/integration/cnh_bonus_standard/index')
        .then(res => {
          this.dataTable = res.data.data
          this.loading = false
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
        })
    },

    async getItemBonusById(bonusId) {
      this.loadingSpinner = bonusId
      await axiosIns.get(`api/v1/integration/cnh_bonus_standard/edit/${bonusId}`)
        .then(res => {
          this.dataToModalById = res.data.data
          this.openModal = true
        })
      this.loadingSpinner = ''
      this.mode = 'edit'
    },

    closeModal() {
      this.openModal = false
    },

    openModalAddCommission() {
      this.mode = 'add'
      this.openModal = true
    },

    async nextPage() {
      this.loading = true
      await axiosIns.get(`api/v1/integration/cnh_bonus_standard/index?page=${this.pageOptions.page}`)
        .then(res => {
          this.dataTable = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
    },

    async updatedTable() {
      await this.getItemsTable()
    },

  },
}
</script>
