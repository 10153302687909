<template>
  <v-card>
    <v-card-title>
      {{ mode === 'edit' ? 'Editar' : 'Adicionar' }}
    </v-card-title>

    <v-container v-if="mode === 'edit'">
      <v-card outlined>
        <v-col>
          <v-text-field
            v-model="bonusData.description"
            :rules="rolesRequired"
            label="Bonificação"
            outlined
            dense
          />
          <v-text-field
            v-model="bonusData.parcel_2"
            label="2° parcela"
            outlined
            dense
          />
          <v-text-field
            v-model="bonusData.parcel_3"
            label="3° parcela"
            outlined
            dense
          />
          <v-text-field
            v-model="bonusData.parcel_4"
            label="4° parcela"
            outlined
            dense
          />
          <v-text-field
            v-model="bonusData.delivery"
            label="Entrega"
            outlined
            dense
          />
          <v-text-field
            v-model="bonusData.base_calc"
            label="Ent. carta"
            outlined
            dense
          />
        </v-col>
      </v-card>
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          color="info"
          @click="updateBunusById()"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-container>

    <v-container v-else>
      <v-card outlined>
        <v-col>
          <v-text-field
            v-model="addDataBonus.description"
            :rules="rolesRequired"
            label="Bonificação"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataBonus.parcel_2"
            label="2° parcela"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataBonus.parcel_3"
            label="3° parcela"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataBonus.parcel_4"
            label="4° parcela"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataBonus.delivery"
            label="Entrega"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataBonus.base_calc"
            label="Ent. carta"
            outlined
            dense
          />
        </v-col>
      </v-card>
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          color="info"
          @click="addBonus()"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-container>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  props: {
    bonusData: {
      type: Object,
      required: true,
      default: () => {},
    },
    mode: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    updatedTable: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      rolesRequired: [r => !!r || 'Campo obrigatório'],
      addDataBonus: {
        description: '',
        parcel_2: '',
        parcel_3: '',
        parcel_4: '',
        delivery: '',
        base_calc: '',
      },
    }
  },

  created() {
  },

  methods: {
    async updateBunusById() {
      await axiosIns.put(`api/v1/integration/cnh_bonus_standard/update/${this.bonusData.id}`, this.bonusData)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados da comissão atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
          this.closeModal()
          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados!',
            showConfirmButton: false,
            text: error,
          })
          this.closeModal()
        })
      await this.updatedTable()
    },
    async addBonus() {
      const dataBonus = {
        description: this.addDataBonus.description,
        parcel_2: this.addDataBonus.parcel_2,
        parcel_3: this.addDataBonus.parcel_3,
        parcel_4: this.addDataBonus.parcel_4,
        delivery: this.addDataBonus.delivery,
        base_calc: this.addDataBonus.base_calc,
      }
      await axiosIns.post('api/v1/integration/cnh_bonus_standard/store/', dataBonus)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados da comissão salvos!',
            showConfirmButton: false,
            timer: 2000,
          })

          this.addDataBonus = {
            description: '',
            parcel_2: '',
            parcel_3: '',
            parcel_4: '',
            delivery: '',
            base_calc: '',
          }

          this.closeModal()
          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao adicionar dados!',
            showConfirmButton: false,
            text: error,
            timer: 2000,
          })
          this.closeModal()
        })
    },
  },

}
</script>
